import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import { Divider } from '@mui/material';
import CircleInfo from '../../../assets/icons/CircleInfo';
import BorderLinearProgress from './LinearProgress';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { formatMRN } from '../../../utils';
import { useMemo, useState } from 'react';
import { colors } from '../../../theme';
import useDataGridSearchQueryArgs from '../../../hooks/useDataGridSearchQueryArgs';
import { useDosespot } from '../DosespotProvider';
import { formatPhone } from '../util/formatPhone';
import { useSearchPatientsQuery } from '../../patients/queries/search-patients.query';
import {
  PatientActiveStatuses,
  PatientSortBy,
} from '@aster/shared/dtos/patient';

type AddPatientsModalProp = {
  incrementStep: () => void;
};

const AddPatients = ({ incrementStep }: AddPatientsModalProp) => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const { paginationModel, searchQuery, sortModel } =
    useDataGridSearchQueryArgs();

  const { arePatientsLoading, paginatedPatients } = useSearchPatientsQuery({
    search: searchQuery,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    sortBy: sortModel?.sortBy as PatientSortBy,
    sortDir: sortModel?.sortDir,
    statusFilter: PatientActiveStatuses,
  });

  const dosespotContext = useDosespot();

  const generateDosespotPatients = async () => {
    setFormError(null);
    setLoading(true);
    const selectedPatients = rows.filter((row) =>
      rowSelectionModel.includes(row.id)
    );
    const { client_id, client_secret, username, password } =
      dosespotContext.dosespotData;

    if (!client_id || !client_secret) {
      setFormError('Could not find dosespot clinic');
    }

    if (!username || !password) {
      setFormError('Could not find dosespot clinician');
    }

    dosespotContext.getDosespotJwt({
      client_id: client_id as string,
      client_secret: client_secret as string,
      username: username as string,
      password: password as string,
    });

    Promise.all(
      selectedPatients.map(async (patient) => {
        const phone = formatPhone(patient.phone);
        await dosespotContext.createDosespotPatient({
          patientId: patient.id,
          FirstName: patient.name,
          LastName: patient.name,
          DateOfBirth: patient.dateOfBirth,
          Address1: patient.address,
          Gender: patient.legalSex,
          City: patient.city,
          State: patient.state,
          ZipCode: patient.zip,
          PrimaryPhone: phone,
          PrimaryPhoneType: '1',
          Active: 'true',
        });
      })
    )
      .then(() => {
        setLoading(false);
        incrementStep();
      })
      .catch((e) => {
        setFormError('Error syncing patients to DoseSpot');
      });
  };

  const rows = useMemo(() => {
    if (!paginatedPatients) return [];

    return paginatedPatients.items
      .filter((r) => !r.doseSpotPatientId)
      .map((r) => {
        return {
          address: r.address ?? '-',
          city: r.city ?? '-',
          dateOfBirth: r.dateOfBirth ?? '-',
          id: r.id,
          legalSex: r.legalSex ?? '-',
          mrn: formatMRN(r.mrn),
          name: `${r.firstName} ${r.lastName}`,
          phone: r.phoneNumber ?? '-',
          state: r.state ?? '-',
          zip: r.zip ?? '-',
        };
      });
  }, [paginatedPatients]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      cellClassName: 'text-asterGray text-semibold border-0',
    },
    {
      field: 'mrn',
      headerName: 'Client ID',
      headerAlign: 'left',
      flex: 0.8,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      description: 'Date of Birth',
      headerAlign: 'left',
      flex: 0.8,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'legalSex',
      headerName: 'Sex',
      description: 'Sex',
      headerAlign: 'left',
      flex: 0.4,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'city',
      headerName: 'City',
      description: 'City',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'state',
      headerName: 'State',
      description: 'State',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'zip',
      headerName: 'Zip',
      description: 'Zipcode',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
  ];

  return (
    <>
      <DialogActions className="flex justify-between p-6">
        <Typography
          variant={'h4'}
          customClass="font-semibold"
          text={'Sync your patients'}
        />
        <div className="flex items-end gap-4">
          <div className="flex flex-col items-center w-[140px]">
            <Typography
              variant="bodySmall"
              customClass="text-asterGray"
              text="3/3 steps"
            />
            <BorderLinearProgress
              variant="determinate"
              className="w-full mt-1"
              value={100}
            />
          </div>
          <ButtonType
            variant="contained"
            onClick={incrementStep}
            text={'Next'}
          />
        </div>
      </DialogActions>
      <Divider />
      <DialogContent className="flex flex-col overflow-visible">
        <div className="flex items-center gap-4 bg-pastelOrange p-3 mt-2 rounded-2xl bg-opacity-[25%]">
          <CircleInfo className="flex shrink-0 h-[24px] w-[24px]" />
          <Typography
            customClass="text-asterGray leading-1"
            variant="bodyMedium"
            text="To add patients to DoseSpot and start prescribing, please ensure all required fields are complete and accurate. Patients already synced with Dosespot will not appear here."
          />
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between items-center my-3">
            <Typography variant="h6" text="Select Patients" />
            <div className="flex items-center gap-3">
              <Typography
                variant="bodySmall"
                text={`${rowSelectionModel.length}/${rows.length} selected`}
              />
              <ButtonType
                loading={loading}
                onClick={generateDosespotPatients}
                variant="outlined"
                text="Sync to Dosespot"
                disabled={rowSelectionModel.length === 0}
              />
            </div>
          </div>
          {formError && (
            <Typography
              customClass="text-red-500 py-2"
              variant="bodySmall"
              text={formError}
            />
          )}
        </div>

        <StyledDataGrid
          checkboxSelection
          loading={arePatientsLoading}
          onRowSelectionModelChange={(newSelection) => {
            setRowSelectionModel(newSelection);
          }}
          isRowSelectable={(params) => {
            return (
              params.row.address !== '-' &&
              params.row.city !== '-' &&
              params.row.dateOfBirth !== '-' &&
              params.row.legalSex !== '-' &&
              params.row.mrn !== '-' &&
              params.row.name !== '-' &&
              params.row.phone !== '-' &&
              params.row.state !== '-' &&
              params.row.zip !== '-'
            );
          }}
          className="grid w-full"
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          disableColumnMenu
          rows={rows}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox': {
              cursor: 'pointer',
              backgroundColor: colors.dataGridGray,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${colors.grayBackground}`,
            },
          }}
        />
      </DialogContent>
    </>
  );
};

export default AddPatients;
